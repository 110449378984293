import React from "react"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { animations } from "src/styles"
import { Link as GatsbyLink } from "gatsby"

const LinkStyles = (theme, showUnderline) => `
	font-size: inherit;
	text-decoration: none;
	cursor: pointer;
	position: relative;
	padding: .2em 0;
	${
    showUnderline
      ? `
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 2px;
			background: var(--light-text-color);
			opacity: .2;
			z-index: 1;
		}
	`
      : `
	`
  }
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 2px;
		background: var(--main-color);
		transform: scaleX(0);
		transform-origin: right center;
		transition: transform ${animations.mediumSpeed} ease-in-out;
		z-index: 2;
	}
	&:hover {
		&:after {
			transform-origin: left center;
			transform: scaleX(1);
		}
	}
`

const StyledLinkElement = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme, showUnderline }) => LinkStyles(theme, showUnderline))

const StyledGatsbyLink = styled(GatsbyLink, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme, showUnderline }) => LinkStyles(theme, showUnderline))

const Link = ({
  to,
  external,
  target,
  children,
  label,
  className,
  showUnderline = false,
  setTheme = "mainColor",
  title,
  as,
  name,
}) => {
  if (external) {
    return (
      <StyledLinkElement
        title={title}
        name={name || title}
        aria-label={name || title}
        className={className}
        href={to}
        as={as || "a"}
        target={target}
        theme={setTheme}
        showUnderline={showUnderline}
        rel="noopener noreferrer"
      >
        {children || label}
      </StyledLinkElement>
    )
  } else {
    return (
      <StyledGatsbyLink
        title={title}
        name={name || title}
        aria-label={name || title}
        className={className}
        to={to}
        theme={setTheme}
        showUnderline={showUnderline}
      >
        {children || label}
      </StyledGatsbyLink>
    )
  }
}

Link.defaultProps = {
  to: "#",
  external: false,
  target: "",
  setTheme: "alert",
}

export default Link
