import React from "react"
import styled from "@emotion/styled"

import Logo from "components/Logo"
import Modal from "src/components/Modal"
import DownloadBadge from "src/assets/images/Download_on_the_App_Store_Badge.svg"

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";


import { colors, mq } from 'src/styles'

const ModalContent = styled.div`
  max-width: 1200px;
  min-height: 40vh;
  padding: 30px;
  width: 90vw;

  ${mq.largeAndUp} {
    width: 60vw;
  }
  ${mq.largeAndBelow} {
    padding-top: 60px;
  }

  .mobile-hide {
    ${mq.largeAndBelow} {
      display: none;
    }
  }
`

const CodeContainer = styled.div`
  width: 200px;
  height: 200px;
  display: -webkit-box;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-top: 10px;
`;

const LogoContainer = styled.div`
  display: inline-block;
  width: 155px;
  svg {
    max-width: 100%;
    height: auto;
    display: block;
  }
`

const CodeBorder = styled.div`
  padding: 0.5em;
  border-radius: 10px;
  background: ${colors.white};
  border: 1px solid ${colors.hrColor};
  margin: 2em 0;
`;

const Glow = styled.div`
  width: 341.345px;
  height: 341.345px;
  transform: rotate(-27.049deg);
  flex-shrink: 0;
  border-radius: 341.345px;
  opacity: 0.3;
  background: #f86065;
  filter: blur(100px);
  position: absolute;
  margin-top: -170.6725px;
  margin-left: -170.6725px;
  top: 75%;
  left: 50%;
  z-index: 1;
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  max-width: 540px;
`

const DownloadModal = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/join.tenderco.co_website.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);
  const image = getImage(data.file);

	return (
		<Modal id="download-modal">
			<ModalContent>
				<Glow />
				<LogoContainer className="mobile-hide">
					<Logo />
				</LogoContainer>
				<Message>
					<h2 className="h2">Download Tender!</h2>
					<p css={{
						maxWidth: 340
					}} className="small center">Scan the QR Code Below or <a rel="noreferrer" target="_blank" href="https://apps.apple.com/app/apple-store/id1658816779?pt=125834556&ct=Website&mt=8"> click here to download</a> the app:</p>
					<CodeBorder>
						<CodeContainer>
              <GatsbyImage image={image} alt="Descripción de la imagen" />
						</CodeContainer>
					</CodeBorder>
					<a rel="noreferrer" target="_blank" href="https://apps.apple.com/app/apple-store/id1658816779?pt=125834556&ct=Website&mt=8">
						<DownloadBadge />
					</a>
					<p css={{
						color: colors.lightTextColor,
						marginTop: '1.5em'
					}} className="tiny center">Tender is currently iOS only. Android friends, stay tuned and we'll email you!</p>
				</Message>
			</ModalContent>
		</Modal>
	)
}

export default DownloadModal
