import { rgba } from "polished"
import * as colors from "./colors"
import { responsiveStyles } from "./util"

import "./fonts"

// Place global Typography in this file
export const primaryFont = `'Sofia Pro', -apple-system, sans-serif`
export const secondaryFont = `'Cooper', serif`
export const normal = "normal"
export const medium = 700
export const bold = 900

export const bodyLarge = `
	${responsiveStyles("font-size", 24, 24, 24, 22)}
	line-height: 1.4em;
	font-family: ${primaryFont};
	letter-spacing: -.01em;
	text-transform: none;
	font-weight: normal;
`
export const bodyMedium = `
	${responsiveStyles("font-size", 22, 22, 20, 20)}
	line-height: 1.6em;
	font-family: ${primaryFont};
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
`
export const body = `
	${responsiveStyles("font-size", 20, 20, 18, 18)}
	line-height: 1.6em;
	font-family: ${primaryFont};
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
`
export const bodySmall = `
	${responsiveStyles("font-size", 18, 18, 16, 16)}
	line-height: 1.6em;
	font-family: ${primaryFont};
	letter-spacing: -.01em;
	text-transform: none;
	font-weight: normal;
`
export const bodyTiny = `
	${responsiveStyles("font-size", 16, 16, 14, 14)}
	line-height: 1.5em;
	font-family: ${primaryFont};
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
`

export const h1 = `
	${responsiveStyles("font-size", 60, 54, 48, 40)}
	line-height: 1.2em;
	font-family: ${secondaryFont};
	font-weight: ${normal};
	letter-spacing: -.02em;
	text-transform: none;
	color: var(--text-color);
`

export const h2 = `
	${responsiveStyles("font-size", 46, 46, 42, 36)}
	line-height: 1.2em;
	font-family: ${secondaryFont};
	font-weight: ${normal};
	letter-spacing: -.02em;
	text-transform: none;
	color: var(--text-color);
`

export const h3 = `
	${responsiveStyles("font-size", 30, 30, 30, 26)}
	line-height: 1.35em;
	font-family: ${primaryFont};
	font-weight: ${bold};
	letter-spacing: -.01em;
	text-transform: none;
	color: var(--text-color);
`

export const h4 = `
	${responsiveStyles("font-size", 24, 24, 22, 20)}
	line-height: 1.3em;
	font-family: ${primaryFont};
	font-weight: ${bold};
	letter-spacing: -.01em;
	text-transform: none;
	color: var(--text-color);
`

export const h5 = `
	${responsiveStyles("font-size", 20, 20, 20, 20)}
	font-family: ${primaryFont};
	font-weight: ${bold};
	line-height: 1.6em;
	letter-spacing: 0;
	letter-spacing: -.01em;
	text-transform: none;
	color: var(--text-color);
`
export const h6 = `
	${responsiveStyles("font-size", 18, 18, 18, 18)}
	font-family: ${primaryFont};
	font-weight: ${bold};
	letter-spacing: -.01em;
	line-height: 1.2em;
	color: var(--text-color);
`

export const blockquote = `
	${h4}
	font-style: normal;
	border-left: 2px solid var(--hr-color);
	padding-left: .5em;
`

export const eyebrow = `
	${body}
	font-weight: ${bold};
`

export const smallCaps = `
	text-transform: uppercase;
	letter-spacing: .05em;
	font-weight: ${medium};
	${responsiveStyles("font-size", 14, 13, 12, 12)}
`

export const buttonStyle = `
	${responsiveStyles("font-size", 16, 16, 15, 15)}
	line-height: 1em;
	font-family: ${primaryFont};
	letter-spacing: 0;
	text-transform: none;
	font-weight: ${bold};
`

export const buttonStyleLarge = `
	${buttonStyle}
	${responsiveStyles("font-size", 18, 18, 16, 16)}
`

export const navStyle = `
	${buttonStyle}
	${responsiveStyles("font-size", 16, 15, 13, 13)}
`

export const storyNotes = `
	max-width: 750px;
	p {
		code {
			background: ${rgba(colors.textColor, 0.1)};
			color: ${colors.textColor};
			border-radius: 3px;
			padding: .05em .35em .15em;
			font-style: normal;
		}
	}
`

export const unstyledButton = `
	appearance: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-touch-callout: none;
	border: none;
	padding: 0;
	margin: 0;
	display: inline-block;
	vertical-align: top;
	outline: none;
	background-color: transparent;
	cursor: pointer;
	perspective: 300px;
	color: inherit;
	text-align: inherit;
`
