import React from "react"

import { Global, css } from "@emotion/react"

import ScrollListener from "components/ScrollListener"
import IntersectionObserverPolyfill from "components/IntersectionObserverPolyfill"
import AppProvider from "src/state/AppState"

import NewsletterModal from "src/components/NewsletterModal"
import DownloadModal from "src/components/DownloadModal"
import NewsletterButton from "src/components/NewsletterButton"

import globalStyles from "styles/globalStyles"

import "./reset.css"

const Layout = ({ children }) => (
  <>
    <Global
      styles={css`
        ${globalStyles}
      `}
    />
    <div id="pageTop" style={{ width: "100%", height: "0" }} />
    <IntersectionObserverPolyfill>
      <AppProvider>
        <ScrollListener>
          <NewsletterButton />
          {children}
        </ScrollListener>
        <NewsletterModal />
        <DownloadModal />
      </AppProvider>
    </IntersectionObserverPolyfill>

    <svg
      style={{ width: 0, height: 0, position: "absolute" }}
      aria-hidden="true"
      focusable="false"
    >
      <linearGradient
        id="logo-gradient"
        x1="0.111298"
        y1="0.432181"
        x2="0.801716"
        y2="0.807377"
      >
        <stop offset="0" stopColor="#7B61FF" />
        <stop offset=".9" stopColor="#FD5F5E" />
      </linearGradient>
    </svg>
  </>
)

export default Layout
