import React, { useContext } from "react"
import { rgba } from "polished"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import ReactModal from "react-modal"
import { AppContext } from "src/state/AppState"
import { colors } from "src/styles"
import Button from "src/components/Button"

const timeout = 500

const Wrapper = styled(ReactModal)``

const modalStyles = () => ({
  content: {
    top: null,
    left: null,
    right: null,
    bottom: null,
    border: null,
    overflow: null,
    WebkitOverflowScrolling: null,
    borderRadius: 20,
    background: colors.bgColor,
    position: "relative",
    outline: "none",
    transition: `opacity ${timeout}ms ease-in-out`,
    margin: "auto",
  },
  overlay: {
    zIndex: 5,
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "click",
    overflow: "auto",
    transition: `background ${timeout}ms ease-in-out`,
    padding: "5vw 0",
  },
})

const drawerStyles = () => ({
  content: {
    top: null,
    left: null,
    right: null,
    bottom: null,
    border: null,
    overflowX: "hidden",
    overflowY: "auto",
    WebkitOverflowScrolling: null,
    borderRadius: 0,
    background: colors.bgColor,
    position: "relative",
    outline: "none",
    transition: `transform ${timeout}ms ease-in-out`,
    margin: "0",
  },
  overlay: {
    zIndex: 5,
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-end",
    pointerEvents: "click",
    overflow: "hidden",
    transition: `background ${timeout}ms ease-in-out`,
    padding: "0",
  },
})

const Modal = ({
  className,
  children,
  id,
  isOpen,
  drawer = false,
  ...rest
}) => {
  const { modal, toggleModal } = useContext(AppContext)
  // return <div>Modal: {modal} ===> {id}</div>
  isOpen = isOpen || id === modal
  return (
    <>
      <Global
        styles={css`
          .ReactModal__Content {
            &:not(.drawer) {
              opacity: 0;

              &.ReactModal__Content--after-open {
                opacity: 1;
              }

              &.ReactModal__Content--before-close {
                opacity: 0;
              }
            }
            &.drawer {
              transform: translate3d(101%, 0, 0);

              &.ReactModal__Content--after-open {
                transform: none;
              }

              &.ReactModal__Content--before-close {
                transform: translate3d(101%, 0, 0);
              }
            }
          }

          .ReactModal__Overlay {
            background: transparent !important;

            &.ReactModal__Overlay--after-open {
              background: ${rgba(colors.textColor, 0.3)} !important;
            }

            &.ReactModal__Overlay--before-close {
              background: transparent !important;
            }
          }
        `}
      />
      <Wrapper
        style={drawer ? drawerStyles(isOpen) : modalStyles(isOpen)}
        className={drawer ? "drawer" : className}
        shouldCloseOnOverlayClick={true}
        onRequestClose={toggleModal}
        isOpen={isOpen}
        closeTimeoutMS={timeout}
        {...rest}
      >
        <Button
          icon="close"
          onClick={() => toggleModal(false)}
          setTheme="beige"
          shape="circle"
          size="small"
          className="close-button"
          css={css`
            position: absolute;
            top: 25px;
            right: 25px;
            z-index: 2;
          `}
        />
        <div style={{ position: "relative", zIndex: 1 }}>{children}</div>
      </Wrapper>
    </>
  )
}

export default Modal
