import MaterialIconsWoff from "../assets/fonts/icons/material-icons-regular.woff"
import MaterialIconsWoff2 from "../assets/fonts/icons/material-icons-regular.woff2"

import SofiaProSemiBoldWoff from "../assets/fonts/sofia/SofiaProSemiBold.woff"
import SofiaProSemiBoldWoff2 from "../assets/fonts/sofia/SofiaProSemiBold.woff2"

import SofiaProMediumWoff from "../assets/fonts/sofia/SofiaProMedium.woff"
import SofiaProMediumWoff2 from "../assets/fonts/sofia/SofiaProMedium.woff2"

import SofiaProRegularWoff from "../assets/fonts/sofia/SofiaProRegular.woff"
import SofiaProRegularWoff2 from "../assets/fonts/sofia/SofiaProRegular.woff2"

import CooperLightWoff from "../assets/fonts/cooper/CooperBT-Light.woff"
import CooperLightWoff2 from "../assets/fonts/cooper/CooperBT-Light.woff2"

export const fontFace = (
  fontName,
  woff,
  woff2,
  fontWeight = "normal",
  fontStyle = "normal"
) => `
	@font-face {
		font-family: '${fontName}';
		src:  url('${woff}') format('woff'),
					url('${woff2}') format('woff2');
		font-weight: ${fontWeight};
		font-style: ${fontStyle};
		font-display: swap;
	}
`
export const MaterialIcons = "Material Icons"
export const MaterialIconsFont = fontFace(
  MaterialIcons,
  MaterialIconsWoff,
  MaterialIconsWoff2
)

export const SofiaPro = "Sofia Pro"
export const SofiaProRegularFont = fontFace(
  SofiaPro,
  SofiaProRegularWoff,
  SofiaProRegularWoff2
)
export const SofiaProMediumFont = fontFace(
  SofiaPro,
  SofiaProMediumWoff,
  SofiaProMediumWoff2,
  700
)
export const SofiaProSemiBoldFont = fontFace(
  SofiaPro,
  SofiaProSemiBoldWoff,
  SofiaProSemiBoldWoff2,
  900
)

export const Cooper = "Cooper"
export const CooperLightFont = fontFace(
  Cooper,
  CooperLightWoff,
  CooperLightWoff2
)
