export const verticalSpacing = (attr = "padding-top", multiple = 1) => `	
	${attr}: calc(var(--vertical-spacing) * ${multiple});
`

export const baseBorderRadius = 12
export const cardBorderRadius = 16

// Buttons, Inputs, Selects, etc.
export const uiElementSizes = {
  extraTiny: 26,
  tiny: 32,
  small: 44,
  medium: 50,
  large: 60,
}

export const responsiveUiSizes = {
  small: 1,
  medium: 1,
  large: 1,
  huge: 1,
}
