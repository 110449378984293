import React from "react"
import styled from "@emotion/styled"

import Modal from "src/components/Modal"

import { mq } from "src/styles"

const ModalContent = styled.div`
  max-width: 1200px;
  min-height: 40vh;
  padding: 30px;
  width: 90vw;

  ${mq.mediumAndUp} {
    width: 60vw;
  }
  ${mq.largeAndUp} {
    width: 50vw;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-top: 3em;
`

// TODO: Implement Beehive

const NewsletterModal = () => (
  <Modal id="newsletter-modal">
    <ModalContent>
      <Container>
        <div>
          <h2 className="h2 center">Stay Updated with Tender</h2>
          <p className="small center">
            Get the latest caregiving tips, Tender updates, and community
            stories directly to your inbox. Join our journey towards smarter,
            more tender care.
          </p>
        </div>
        <div>
          <iframe src="https://embeds.beehiiv.com/0845f66d-6a57-4d69-be7c-f883fd9a5ab7?slim=true" />
        </div>
      </Container>
    </ModalContent>
  </Modal>
)

export default NewsletterModal
