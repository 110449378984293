import React, { useContext, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { AppContext } from "src/state/AppState"

import Button from "src/components/Button"
import ScrollListener from "src/components/ScrollListener"
import { baseBorderRadius, uiElementSizes } from "src/styles/globals"

import { animations } from "src/styles"
const Container = styled.div`
  position: fixed;
  left: var(--site-margins);
  bottom: 0;
  z-index: 4;
  display: flex;
  gap: 4px;
  align-items: center;
  transition: transform ${animations.mediumSpeed} ease-in-out;
  ${({ hidden }) =>
    hidden
      ? `transform: translateY(${uiElementSizes.large + 5}px)`
      : `translateY(0)`}
`

const NewsletterButton = () => {
  // TODO, show / hide on scroll logic
  // TODO, add dismiss button
  const { toggleModal } = useContext(AppContext)

  const [dismissed, setDismissed] = useState(false)

  if (dismissed) {
    return null
  }

  return (
    <ScrollListener.Consumer>
      {({ scrolledToTop, scrolledUp }) => {
        const hidden = scrolledToTop || scrolledUp
        return (
          <Container hidden={hidden}>
            <Button
              onClick={() => toggleModal("newsletter-modal")}
              css={css`
                border-radius: ${baseBorderRadius}px ${baseBorderRadius}px 0 0;
              `}
            >
              Get our Newsletter
            </Button>
            <Button
              icon="close"
              onClick={() => setDismissed(true)}
              setTheme="beige"
              shape="circle"
              size="tiny"
              className="close-button"
              css={css`
                position: absolute;
                top: -12px;
                right: -12px;
                z-index: 2;
              `}
            />
          </Container>
        )
      }}
    </ScrollListener.Consumer>
  )
}

export default NewsletterButton
