import { lighten, darken } from "polished"

export const black = "#000"
export const white = "#fff"

// Site Specific Colors
export const navy = "#02166A"
export const navyDark = "#02166A"
export const green = "#14A88B"
export const tan = "#FDFDFC"
export const midTan = "#F3EFEC"
export const periwinkle = "#6180f3"
export const purple = "#7b61ff"

export const red = "#c7440c"
export const brightRed = "#F86065"
export const blue = "#16C2E8"
export const yellow = "#FFD780"
export const orange = "#FEA621"

export const lightGrey = "#f2f2ef"
export const darkGrey = "#7381AB"

export const pop = periwinkle

// Basic Colors
export const transparent = "transparent"
export const currentcolor = "currentcolor"

export const textColor = navy
export const bgColor = tan
export const mainColor = periwinkle

export const alert = red
export const notify = yellow
export const success = green

// Color Variations
export const lightTextColor = "#888D89"
export const hrColor = "#C8C5B4"
export const mainColorDarken = darken(0.07, mainColor)
export const mainColorLighten = lighten(0.07, mainColor)
