import * as colors from "./colors"
import { lighten, darken, tint } from "polished"

// Themes (ThemeSelector Component)
export const themes = {
  default: {
    color: colors.textColor,
    lightColor: colors.lightTextColor,
    background: colors.bgColor,
    hoverColor: colors.mainColor,
    buttonTheme: "default",
    buttonThemeSecondary: "textColor",
  },
  lightGrey: {
    color: colors.textColor,
    lightColor: colors.lightTextColor,
    background: colors.lightGrey,
    hoverColor: colors.mainColor,
    buttonTheme: "default",
    buttonThemeSecondary: "textColor",
  },
  green: {
    color: colors.bgColor,
    lightColor: tint(0.8, colors.textColor),
    background: colors.green,
    hoverColor: colors.mainColor,
    buttonTheme: "navy",
    buttonThemeSecondary: "white",
    hrColor: "rgba(255, 255, 255, .3)",
  },
  navy: {
    color: colors.bgColor,
    lightColor: tint(0.8, colors.textColor),
    background: colors.textColor,
    hoverColor: colors.mainColor,
    buttonTheme: "default",
    buttonThemeSecondary: "white",
    hrColor: "rgba(255, 255, 255, .3)",
  },
  navyDark: {
    color: colors.bgColor,
    lightColor: tint(0.8, colors.textColor),
    background: colors.navyDark,
    hoverColor: colors.mainColor,
    buttonTheme: "white",
    buttonThemeSecondary: "default",
    hrColor: "rgba(255, 255, 255, .3)",
  },
}

// Button Themes
export const buttonThemes = {
  default: {
    color: colors.bgColor,
    background: colors.mainColor,
    hoverColor: colors.bgColor,
    hoverBackground: darken(0.07, colors.mainColor),
  },
  transparent: {
    color: colors.lightTextColor,
    background: "transparent",
    hoverColor: colors.textColor,
    hoverBackground: "transparent",
  },
  currentcolor: {
    color: colors.textColor,
    background: "currentcolor",
    hoverColor: "currentcolor",
    hoverBackground: "currentcolor",
  },
  currentcolorOutlined: {
    color: "currentcolor",
    background: "transparent",
    hoverColor: colors.textColor,
    hoverBackground: "currentcolor",
    borderColor: "currentcolor",
  },
  navy: {
    color: colors.bgColor,
    background: colors.navy,
    hoverColor: colors.white,
    hoverBackground: darken(0.07, colors.navy),
  },
  navyDark: {
    color: colors.bgColor,
    background: colors.navyDark,
    hoverColor: colors.white,
    hoverBackground: darken(0.07, colors.navyDark),
  },
  white: {
    color: colors.textColor,
    background: colors.white,
    hoverColor: colors.white,
    hoverBackground: colors.mainColor,
  },
  beige: {
    color: colors.textColor,
    background: colors.midTan,
    hoverColor: colors.textColor,
    hoverBackground: darken(0.07, colors.midTan),
  },
  lightGrey: {
    color: colors.textColor,
    background: colors.lightGrey,
    hoverColor: colors.textColor,
    hoverBackground: darken(0.07, colors.lightGrey),
  },
  mainColor: {
    color: colors.bgColor,
    background: colors.mainColor,
    hoverColor: colors.bgColor,
    hoverBackground: lighten(0.07, colors.mainColor),
  },
  textColor: {
    color: colors.bgColor,
    background: colors.textColor,
    hoverColor: colors.bgColor,
    hoverBackground: colors.mainColor,
  },
}

// Input Themes
export const inputThemes = {
  default: {
    color: colors.textColor,
    placeholderColor: colors.lightTextColor,
    background: colors.transparent,
    accentColor: colors.mainColor,
    hoverColor: colors.bgColor,
    borderColor: colors.textColor,
    hoverBorderColor: colors.textColor,
    focusBorderColor: colors.mainColor,
    hoverBackground: colors.transparent,
    focusBackground: colors.transparent,
  },
  light: {
    color: "var(--text-color)",
    placeholderColor: "var(--light-text-color)",
    background: colors.bgColor,
    accentColor: colors.mainColor,
    hoverColor: colors.bgColor,
    borderColor: "var(--hr-color)",
    hoverBorderColor: "var(--text-color)",
    focusBorderColor: "var(--text-color)",
    hoverBackground: colors.bgColor,
    focusBackground: colors.bgColor,
  },
}

export default themes
